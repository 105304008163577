
import { defineComponent, onBeforeMount, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import axios from "axios";
import { RightOutlined } from "@ant-design/icons-vue";
export default defineComponent({
  name: "teamDetails",
  components: {
    RightOutlined,
  },
  setup() {
    const router = useRouter();
    const dataSource = ref({});
    const route = useRoute();
    const title = ref();
    const titleName = ref('')

    onBeforeMount(() => {
      let id = route.query.id;
      title.value = route.meta.title;
      const url = process.env.NODE_ENV == "production" ? 
      "/data/teamDetails.json" : "/data/teamDetails.json";
      axios.get(url).then((res: any) => {
        if (res.status == 200) {
          switch (id) {
            case "1":
              dataSource.value = res.data.id1;
              break;
            case "2":
              dataSource.value = res.data.id2;
              break;
            case "3":
              dataSource.value = res.data.id3;
              break;
            case "4":
              dataSource.value = res.data.id4;
              break;
            default:
              break;
          }
          titleName.value = (dataSource.value as any).name.split('&').join(' ');
        }
      });
    });
    const tocoreTeam = (): void => {
      location.href = process.env.NODE_ENV == "production"?`/team`:`/team`;
    };
    return {
      title,
      dataSource,
      tocoreTeam,
      titleName
    };
  },
});
